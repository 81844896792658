<template>
  <div class="align window-height-contact">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">Contact</h2>
      <p class="section-paragraph">Our company is located in Belgium</p>
    </div>

    <div class="description moveUp" v-scrollanimation>
      <div class="info">
        <div class="map">
          <iframe
            frameborder="0"
            style="border: 0"
            allowfullscreen
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2516.122653159471!2d4.650635215714615!3d50.902946579540306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c1606921ba2f53%3A0xa96bb3c6dda58ac1!2sKlein%20Dalenstraat%2045c%2C%203020%20Herent!5e0!3m2!1sen!2sbe!4v1661021431832!5m2!1sen!2sbe"
            loading="lazy"
            class="iframe-map"
          />
        </div>
        <ul style="padding: 4px">
          <li>
            <q-icon :name="mdiMapMarkerRadius" class="icons" />
            <div class="section">Location:</div>
            <p>
              Klein Dalenstraat 45/C<br />
              3020 Herent<br />
              Belgium
            </p>
          </li>
          <li>
            <q-icon :name="mdiEmailMarkAsUnread" class="icons" />
            <div class="section">Email:</div>
            <p>support@iThermai.com</p>
          </li>
          <li>
            <q-icon :name="mdiPhonePlus" class="icons" />
            <div class="section">Call:</div>
            <p>+32 489 00 2283</p>
          </li>
        </ul>
      </div>
      <div class="contact-form">
        <q-form
          @submit="onSubmit()"
          @reset="onReset()"
          class="q-col-gutter-sm"
          style="display: flex; flex-wrap: wrap"
        >
          <q-input
            color="field"
            filled
            v-model="name"
            label="Your name *"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
            class="half-flex"
          />

          <q-input
            color="field"
            filled
            v-model="email"
            label="Your email *"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
              () => isValidEmail() || 'Please enter a valid email',
            ]"
            class="half-flex"
          />

          <q-input
            color="field"
            filled
            v-model="subject"
            label="Your Subject *"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
            class="full-flex"
          />

          <q-input
            color="field"
            filled
            v-model="text"
            label="Your text *"
            lazy-rules
            type="textarea"
            rows="11"
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
            class="full-flex"
          />

          <vue-recaptcha
            :sitekey="siteKey"
            :load-recaptcha-script="true"
            @verify="handleSuccess"
            @error="handleError"
            ref="recaptcha"
            class="recaptcha full-flex"
            theme="dark"
          ></vue-recaptcha>

          <div class="full-flex">
            <q-btn label="Submit" type="submit" color="btns" :disable="busy" />
            <q-btn label="Reset" type="reset" flat color="btns" />
          </div>
        </q-form>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useQuasar } from "quasar";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import {
  mdiMapMarkerRadius,
  mdiEmailMarkAsUnread,
  mdiPhonePlus,
} from "@quasar/extras/mdi-v6";

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const $q = useQuasar();

    const name = ref(null);
    const email = ref(null);
    const subject = ref(null);
    const text = ref(null);
    const busy = ref(false);
    const valid = ref(false);

    const recaptcha = ref(null);
    const siteKey = computed(() => {
      return "6LdEZM8iAAAAAFs45ZVTQOoLnLvYTnkJwqG3c1Dy";
    });

    const handleError = () => {
      console.log("error");
      valid.value = false;
    };

    const handleSuccess = () => {
      // receives the response which is the token
      valid.value = true;
    };

    const isValidEmail = () => {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(email.value);
    };

    const onSubmit = async () => {
      if (valid.value !== true) {
        $q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          message: "You need to check the checkbox!",
          timeout: 2000,
        });
      } else {
        busy.value = true;

        let info = {
          name: name.value,
          email: email.value,
          subject: subject.value,
          text: text.value,
        };

        await axios
          .post("/api/sendEmail", info)
          .then(() => {
            $q.notify({
              color: "green-4",
              textColor: "white",
              icon: "cloud_done",
              message:
                "Your message is received. We will get back to you soon.",
              timeout: 3500,
            });
          })
          .catch((err) => {
            console.log(err);
            $q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              message: "Try again!",
              timeout: 2000,
            });
          });

        busy.value = false;
      }
    };

    const onReset = () => {
      name.value = null;
      email.value = null;
      subject.value = null;
      text.value = null;
      valid.value = false;
      recaptcha.value.reset();
    };

    return {
      name,
      email,
      subject,
      text,
      isValidEmail,
      onReset,
      onSubmit,
      busy,
      handleSuccess,
      handleError,
      siteKey,
      recaptcha,
      mdiMapMarkerRadius,
      mdiEmailMarkAsUnread,
      mdiPhonePlus,
    };
  },
};
</script>

<style scoped>
@import "../../styles/globalStyles.scss";

.description {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info,
.map {
  margin: 7px;
}

ul {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}

.description .section {
  display: inline;
  padding-left: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 100%;
  color: #1c2201;
  font-weight: bold;
}
.description p {
  padding-left: 30px;
  padding-top: 0;
  margin-top: 0;
  font-size: 70%;
  color: #434701;
}
.description .info {
  flex: 1;
}
.description .contact-form {
  flex: 1;
}
.icons {
  font-size: 35px;
  color: #121501;
  vertical-align: top;
}
.contact-form {
  padding: 5px;
}
.full-flex {
  flex: 100%;
}
.half-flex {
  flex: 50%;
}
.iframe-map {
  width: 100%;
  height: 250px;
  border: 0;
}
@media all and (max-width: 1180px) {
  .half-flex {
    flex: 100%;
  }
  .iframe-map {
    height: 340px;
  }
}

@media all and (max-width: 700px) {
  .description {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
}
@media (max-width: 600px) {
  .description p {
    padding-left: 0;
  }
  .recaptcha {
    left: 50%;
    position: relative;
    transform-origin: center;
    transform: translateX(-50%) scale(0.65);
  }
}
</style>
